import cacheApi from '@/core/cacheApi'
import { toast } from 'vue3-toastify'

import { formatProduct } from '@/helpers/formatProduct'
import type { ApiErrorResponse, ApiResponse } from '@/types/apiResponse'
import type { QueryParameters } from '@/types'
import { useLanguage } from '@/composables/language'
const { mainLang } = useLanguage()
export async function getSearchProducts({ search }: any): Promise<ApiResponse> {
  try {
    const { data, headers } = await cacheApi.get(`/wp-json/treesal/v1/internal/smart-search`, {
      params: {
        show_hidden: true,
        variable: true,
        query: search
      }
    })
    return {
      success: true,
      data: data
    }
  } catch (error) {
    toast.error('حدث خطأ في العملية')
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
