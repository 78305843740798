import cacheApi from '@/core/cacheApi'
import { toast } from 'vue3-toastify'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'

export async function getProductById(id: number | string): Promise<ApiResponse> {
  try {
    const { data, status } = await cacheApi.get(`/wp-json/wc/v3/products/${id}`)

    if (status !== 200) {
      return {
        success: false
      }
    }
    return {
      success: true,
      data
    }
  } catch (error) {
    toast.error('حدث خطأ في العملية')
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
