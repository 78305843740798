import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'

type User = {
  id: string
  email: string
  nicename: string
  firstName: string
  lastName: string
  displayName: string
  token: string
}

interface UserState {
  user: Ref<User | null>
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: ref(null)
  }),
  actions: {
    setUser(user: User | null) {
      this.user = user
    }
  },
  persist: true
})
