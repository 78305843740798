export function useLanguage() {
  const mainLang: 'ar' | 'en' | 'en_US' = import.meta.env.VITE_MAIN_LANG
  const secondLang: string = import.meta.env.VITE_SECOND_LANG
  const mainInputTitlePrefix = mainLang == 'ar' ? 'بالعربية' : 'بالانجليزية'
  const secondInputTitlePrefix = secondLang == 'ar' ? 'بالعربية' : 'بالانجليزية'
  const isTreesalTwoLang = import.meta.env.VITE_TREESAL_TWO_LANG === 'true' ? true : false

  return {
    mainLang,
    secondLang,
    mainInputTitlePrefix,
    secondInputTitlePrefix,
    isTreesalTwoLang
  }
}
