import api from '@/core/api'
import { toast } from 'vue3-toastify'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'

export async function createAttribute(createData: {
  name: string
  type: string
  translation_strings?: { [key: string]: string }
}): Promise<ApiResponse> {
  try {
    const requestBody = {
      name: createData.name.trim(),
      type: createData.type.trim(),
      translation_strings: createData.translation_strings
    }
    console.log(requestBody)
    const { data, status } = await api.post(`/wp-json/wc/v3/products/attributes`, requestBody)
    if (status !== 201) {
      toast.error('حدث خطأ في العملية')
      return {
        success: false
      }
    }
    toast.success(`تم انشاء الوحدة بنجاح`)
    return {
      success: true,
      data
    }
  } catch (error) {
    toast.error('حدث خطأ في العملية')
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
