import api from '@/core/api'
import { toast } from 'vue3-toastify'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'

export async function deleteAttribute(id: number): Promise<ApiResponse> {
  try {
    await api.delete(`/wp-json/wc/v3/products/attributes/${id}?force=true`)
    toast.success(`تم حذف الوحدة بنجاح`)
    return {
      success: true
    }
  } catch (error) {
    toast.error('حدث خطأ في العملية')
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
