/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import pinia from '@/stores'
import router from '@/router'
import vuetify from './vuetify/vuetify'
import Vue3Toasity, { type ToastContainerOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
// Types
import type { App } from 'vue'

export function registerPlugins(app: App) {
  app
    .use(router)
    .use(pinia)
    .use(vuetify)
    .use(Vue3Toasity, { autoClose: 3000, rtl: true } as ToastContainerOptions)
}
