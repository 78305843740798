import api from '@/core/api'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'

import { toast } from 'vue3-toastify'

export async function deleteProduct(id: number | string): Promise<ApiResponse> {
  try {
    await api.delete(`/wp-json/wc/v3/products/${id}`)
    toast.success(`تم حذف المنتج بنجاح`)

    return {
      success: true
    }
  } catch (error) {
    toast.error('حدث خطأ في العملية')
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
